import React from 'react';
import constants from '../../constants.json';
const Pricing = () => {
    return (
        <>
            <section id='pricing' className="pricingWeb">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="title">
                                <span className="subtitle">
                                    Pricing – <span className='color-yellow' style={{ fontWeight: 'bold' }}>Now booking for Q4 2024</span>
                                </span>
                                <h2 className="title-gradient">
                                    Don't pay <span className="color-yellow">$5,000 to $15,000</span> for a website!
                                </h2>
                                <p className="lead">
                                    Thanks to our strategic location in Colombia, we offer affordable pricing across four different products, each with its own set of features. Your investment is protected through secure payment methods like Stripe and PayPal 🔒
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container container-large">
                    <div className="row">
                        <div className="col-12">

                            <div className="pricing-items">
                                <div className="pricing-item product">

                                    <div className="pricing-item__headline">
                                        <span className='name'>Landing Pages</span>
                                        <div className='d-flex' style={{ gap: '10px' }}>
                                            <span>One off</span>
                                            <span className='available'>Two spots available</span>
                                        </div>
                                    </div>
                                    <div className="pricing-item__price">
                                        <span className='title-gradient'>
                                            <span className='starting'>Starting at</span>
                                            $399
                                        </span>
                                        <span>USD</span>
                                    </div>
                                    <div className="pricing-item__description">
                                        <p className='small'>
                                            Launch an eye-catching landing page in days, not months 🚀
                                        </p>
                                        <p>
                                            Perfect for fast and conversion-focused websites that need to be launched in days, not months.
                                        </p>
                                    </div>
                                    <div className="pricing-item__action">
                                        <a href={constants.site.bookingCall} target='_blank' rel='noopener noreferrer' className="button">Secure your Spot <span className="arrow">&rarr;</span></a>
                                    </div>
                                    <div className="pricing-item__features">
                                        <p>Features:</p>
                                        <ul>
                                            <li className='white'><span>✅</span> Custom, High-end Design</li>
                                            <li className='white'><span>✅</span> Free Professional Copywriting</li>
                                            <li><span>✅</span> 4 Hero iterations</li>
                                            <li><span>✅</span> Kick-off strategy call</li>
                                            <li><span>✅</span> 2x rounds of revisions</li>
                                            <li><span>✅</span> Updates every 48 hours</li>
                                            <li className='white'><span>✅</span> React or WordPress Development</li>
                                            <li><span>✅</span> Mobile, Tablet & Desktop layouts</li>
                                        </ul>
                                    </div>

                                </div>
                                <div className="pricing-item product">
                                    <div className="pricing-item__headline">
                                        <span className='name'>WordPress &amp; Marketing Sites</span>
                                        <div className='d-flex' style={{ gap: '10px' }}>
                                            <span>One off</span>
                                            <span className='available'>Three spots available</span>
                                        </div>
                                    </div>
                                    <div className="pricing-item__price">
                                        <span className='title-gradient'>
                                            <span className='starting'>Starting at</span>
                                            $899
                                        </span>
                                        <span>USD</span>
                                    </div>
                                    <div className="pricing-item__description">
                                        <p className='small'>
                                            If you want a fully custom site, top-tier design, and a team that will build it, this is the best option.
                                        </p>
                                        <p>
                                            Perfect for businesses that want a fully customized site with a top-tier design, and compelling copywriting.
                                        </p>

                                    </div>
                                    <div className="pricing-item__action">
                                        <a href={constants.site.bookingCall} target='_blank' rel='noopener noreferrer' className="button">Secure your Spot <span className="arrow">&rarr;</span></a>
                                    </div>
                                    <div className="pricing-item__features">
                                        <p>Features:</p>
                                        <ul>
                                            <li className='white'><span>✅</span> Custom, High-end Design</li>
                                            <li className='white'><span>✅</span> Google Analytics & SEO</li>
                                            <li className='white'><span>✅</span> Unlimited Internal Pages</li>
                                            <li className='white'><span>✅</span> Free Professional Copywriting</li>
                                            <li><span>✅</span> 4 Hero iterations</li>
                                            <li><span>✅</span> Kick-off strategy call</li>
                                            <li><span>✅</span> 4x rounds of revisions</li>
                                            <li><span>✅</span> Updates every 36 hours</li>
                                            <li><span>✅</span> React or WordPress Development</li>
                                            <li><span>✅</span> Mobile, Tablet & Desktop layouts</li>
                                            <li><span>✅</span> 6-months FREE support</li>
                                            <li className='white'><span>✅</span> Free 1 year Hosting & Domain</li>
                                        </ul>
                                    </div>

                                </div>
                                <div className="pricing-item product">
                                    <div className="pricing-item__headline">
                                        <span className='name'>Custom Sites &amp; E-commerce</span>
                                        <div className='d-flex' style={{ gap: '10px' }}>
                                            <span>One off</span>
                                            <span className='available'>One spot available</span>
                                        </div>
                                    </div>
                                    <div className="pricing-item__price">
                                        <span className='title-gradient'>
                                            <span className='starting'>Starting at</span>
                                            $1099
                                        </span>
                                        <span>USD</span>
                                    </div>
                                    <div className="pricing-item__description">
                                        <p className='small'>
                                            Sell and scale your business with a fully custom site, catalog, or e-commerce platform.
                                        </p>
                                        <p>
                                            Best option for selling products or services online with a fully custom site, catalog, or e-commerce platform.
                                        </p>

                                    </div>
                                    <div className="pricing-item__action">
                                        <a href={constants.site.bookingCall} target='_blank' rel='noopener noreferrer' className="button">Secure your Spot <span className="arrow">&rarr;</span></a>
                                    </div>
                                    <div className="pricing-item__features">
                                        <p>Features:</p>
                                        <ul>

                                            <li className='white'><span>✅</span> Custom, High-end Design</li>
                                            <li className='white'><span>✅</span> Google Analytics & SEO</li>
                                            <li className='white'><span>✅</span> Unlimited Internal Pages</li>
                                            <li className='white'><span>✅</span> Unlimited Products</li>
                                            <li className='white'><span>✅</span> Unlimited Categories, Tags and Attributes</li>
                                            <li className='white'><span>✅</span> Invoice & Payment Gateway Integration (Stripe, PayPal, etc.)</li>
                                            <li className='white'><span>✅</span> Free Professional Copywriting</li>
                                            <li><span>✅</span> 4 Hero iterations</li>
                                            <li><span>✅</span> Kick-off strategy call</li>
                                            <li><span>✅</span> 4x rounds of revisions</li>
                                            <li><span>✅</span> Updates every 24 hours</li>
                                            <li><span>✅</span> React, Laravel or WordPress Development</li>
                                            <li><span>✅</span> Mobile, Tablet & Desktop layouts</li>
                                            <li className='white'><span>✅</span> 6-months FREE support</li>
                                            <li className='white'><span>✅</span> Free 1 year Hosting & Domain</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="pricing-item retainer product">

                                <div className="pricing-item__headline">
                                    <span className='name'>All-in-One Website Growth &amp; Management</span>
                                    <span>Monthly Retainer</span>
                                </div>
                                <div className="pricing-item__price">
                                    <span className='title-gradient'>$999</span>
                                    <span>USD</span>
                                </div>
                                <div className="pricing-item__description">
                                    <p className='small'>
                                        Billed monthly, no lock-ins, cancel anytime.
                                    </p>
                                    <p>
                                        Perfect for entrepreneurs and small to medium-sized businesses who want to focus solely on growing their business, while a dedicated, professional team ensures their website looks exceptional, stays up-to-date, drives traffic<sup>1</sup>, and generates revenue<sup>2</sup>.
                                    </p>
                                </div>

                                <div className="pricing-item__people">

                                    <div className="people-name">
                                        <img src="https://wp.vpixel.co/wp-content/uploads/2024/10/65.jpg" alt="Emma Thompson" loading="lazy" />
                                        <div>
                                            <span className='name'>Emma Thompson</span>
                                            <span className='company'>Founder, FitLife Coaching</span>
                                        </div>
                                    </div>
                                    <div className="people-quote">
                                        <blockquote>
                                            “I have worked with VPixel CO for about a year and a half, and I have truly seen substantial improvements on my website. It’s always up-to-date, fast, and attracts more and more traffic each day, continually enhancing my search ranking.”
                                        </blockquote>
                                    </div>
                                </div>


                                <div className="pricing-item__action">
                                    <a href={constants.site.bookingCall} target='_blank' rel='noopener noreferrer' className="button">Secure your Spot <span className="arrow">&rarr;</span></a>
                                    <a href={constants.site.contactLink} target='_blank' rel='noopener noreferrer' className="button button--bordered">
                                        <img style={{ borderRadius: '100px', marginRight: '10px' }} src="/images/1665701539301.jpeg" alt="Erick" width="30" />
                                        Chat with Erick <span className="arrow"></span></a>
                                </div>
                                <div className="pricing-item__features">
                                    <p>Monthly Retainer Includes:</p>
                                    <ul>
                                        <li><span>✅</span> UI/UX designer for creating beautiful, high-converting designs</li>
                                        <li><span>✅</span> Copywriter crafting persuasive, revenue-driving content</li>
                                        <li><span>✅</span> SEO & marketing expert to skyrocket your search rankings</li>
                                        <li><span>✅</span> VIP project manager ensuring your success</li>
                                        <li><span>✅</span> Support for multiple websites - perfect for growing businesses</li>
                                        <li><span>✅</span> Unlimited premium content creation & strategy ($1500+ value)</li>
                                        <li><span>✅</span> Unlimited content updates to keep you ahead of competition</li>
                                        <li><span>✅</span> Unlimited professional design updates ($1000+ value/month)</li>
                                        <li><span>✅</span> Premium 24/7 website maintenance & security</li>
                                        <li><span>✅</span> 48 hours monthly development time ($4800 value)</li>
                                        <li><span>✅</span> VIP fast-track support response</li>
                                        <li><span>✅</span> Growth strategy sessions to maximize your ROI</li>
                                        <li><span>✅</span> Comprehensive analytics & performance reports</li>
                                        <li><span>✅</span> Enterprise-grade communication tools included</li>
                                    </ul>
                                </div>
                            </div>
                            <p className="small terms">
                                <sup>1</sup>Investment in traffic is not included. <sup>2</sup>Revenue is not 100% guaranteed due to external factors. Secure payments are handled by Stripe and Paypal. We do not store any payment information. All our services are covered by a non-disclosure agreement and protected by a 100% satisfaction guarantee and refund policy.
                            </p>
                        </div>
                    </div>
                </div>

                <div className="container">
                    <div className="cta">
                        <img src="/images/1665701539301.jpeg" alt="Erick" width="50" />
                        <div style={{ gap: '10px' }} className="d-flex flex-column align-items-center">
                            <span>Questions? Let's talk!</span>
                            <span className="text">
                                Chat with Erick, one of our founders, to explore how we can help you grow your business in no time!
                            </span>
                            <a href={constants.site.bookingCall} aria-label="Book a Call Now" className="button">Book Now <span className="arrow">&rarr;</span></a>
                        </div>
                    </div>
                </div>
            </section >

        </>
    );
};

export default Pricing;