// import { span } from 'framer-motion/client';
import React, { useState, useEffect } from 'react';
import { translations } from '../../translations/languages';

const ProjectsList = ({ language = 'en' }) => {
    const t = translations[language] || translations.en;

    const [projects, setProjects] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    // const [page, setPage] = useState(1);
    const [setHasMore] = useState(true);

    const fetchProjects = async (pageNum) => {
        try {
            const response = await fetch(`https://wp.vpixel.co/wp-json/wp/v2/work?per_page=50&_embed`);
            if (!response.ok) {
                if (response.status === 400) {
                    setHasMore(false);
                    return [];
                }
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data = await response.json();
            return data;
        } catch (e) {
            setError('Failed to fetch projects');
            return [];
        }
    };

    useEffect(() => {
        const loadInitialProjects = async () => {
            const initialProjects = await fetchProjects(1);
            setProjects(initialProjects);
            setIsLoading(false);
        };

        loadInitialProjects();
    });

    // Add this function to decode HTML entities
    const decodeHtmlEntities = (text) => {
        const textArea = document.createElement('textarea');
        textArea.innerHTML = text;
        return textArea.value;
    };

    if (isLoading) return <div>Loading...</div>;
    if (error) return <div>{error}</div>;

    return (
        <div className="projects-list d-flex flex-column">
            {projects.map((project) => (
                <div key={project.id} className="project-item d-flex flex-column">
                    <figure className="nostatic" style={{ backgroundImage: `url(${project._embedded["wp:featuredmedia"][0].source_url})` }}>
                        <span className="tag">{project.acf.industry}</span>
                    </figure>

                    <div className="project-item__info">
                        <div className="d-flex info">
                            <h3 className=''>{decodeHtmlEntities(project.title.rendered)}</h3>
                        </div>
                        <div className="services">
                            {project.acf.servicesFinal.length === 1 ? (
                                <span>{project.acf.servicesFinal[0]}</span>
                            ) : (
                                project.acf.servicesFinal.map((service, index) => (
                                    <span key={service}>
                                        {service}
                                        {index < project.acf.servicesFinal.length - 1 ? ', ' : ''}
                                    </span>
                                ))
                            )}
                        </div>
                    </div>

                    {project.acf.colaborated_with && (
                        <div className="collab">
                            <div dangerouslySetInnerHTML={{ __html: project.acf.colaborated_with }} />
                        </div>
                    )}
                    <a target='_blank' rel="noreferrer" aria-label="See this project LIVE" href={project.acf.url} className="button button--bordered">{t.seeThisLive}</a>
                </div>
            ))}
        </div>
    );
};

export default ProjectsList;